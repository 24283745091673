import React from 'react';
import '../styles/agency-description.scss';

const AgencyDescriptionPage = () => {
  return (
    <section className="agency-description">
      <div className="description-container">
        When you choose TDVB Group, you make a cultural choice. Not just another stereotypical
        marketing agency, our extensive expertise in all necessary fields provides serious,
        transparent, and concrete services that will firmly establish your brand in an increasingly
        fast-paced and interconnected world.
      </div>

      <p className="title"> VISION </p>
    </section>
  );
};

export default AgencyDescriptionPage;
